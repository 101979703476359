








































import { Component, Vue } from 'vue-property-decorator'
import NavMenuBar from '@/components/NavMenuBar.vue'
import HelpCenterService from '@/services/HelpCenterService'

@Component({ components: { NavMenuBar } })
export default class HelpDetail extends Vue {
  private helperDetail: any = {}
  private loadingHelperDetail: boolean = false
  private showMsgError: boolean = false

  created() {
    this.getHelpCenterDetail()
  }

  async getHelpCenterDetail() {
    let id = Number(this.$route.params.helpCenterId)

    this.loadingHelperDetail = true
    await HelpCenterService.getHelpCenterDetail(id)
      .then((res: any) => {
        if (res.status === 200) {
          this.helperDetail = res.data
        }
      })
      .catch((err: any) => {
        if (err.response.status == 404) {
          this.showMsgError = true
        } else {
          console.log(err)
        }
      })
    this.loadingHelperDetail = false
  }

  goBackToHelpCenter() {
    this.$router.push({ name: 'help-center' })
  }
}
