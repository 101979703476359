import Const from '../constants'
import axiosApiInstance from '@/interceptors'

class AuthService {
  getHelpCenterCategory() {
    return axiosApiInstance.get(Const.api_url + 'help-centers')
  }

  getHelpCenterDetail(id: number) {
    return axiosApiInstance.get(Const.api_url + 'help-centers/' + id)
  }
}

export default new AuthService
